import { Avatar, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

import type { PublicUser } from '@miimosa/common/types'
import { Button, Link, LinkV2 } from '@miimosa/design-system/components'
import IconLogout from '@miimosa/design-system/components/icons/Logout'
import { toV2Link } from '@miimosa/design-system/lib//hooks/use_v2_link'
import { useLogout, usePlatform } from '@miimosa/design-system/lib/hooks'

interface Props {
  user: PublicUser | undefined
}

const MobileNav: FC<Props> = ({ user }) => {
  const logout = useLogout()
  const { platform, lang } = usePlatform()

  return (
    <Flex direction="column" justifyContent="space-between" color="bg.0" px="4" pt="48px" pb="32px" height="100%">
      <Flex direction="column">
        {user && (
          <Flex>
            <Avatar mr="16px" size="lg" mb="12" name={user?.initials} src={user?.avatar ?? undefined} />
            <Heading size="md" color="white">
              {user?.full_name}
            </Heading>
          </Flex>
        )}

        <Link href="/projects" size="lg" mb="24px">
          tous les projets
        </Link>
        <Link href="/manifest" size="lg" mb="24px">
          notre manifeste
        </Link>
        <Link href="/faq" size="lg" mb="24px">
          faq
        </Link>
        {user && (
          <>
            <Text size="lg" mb="24px">
              mon compte
            </Text>
            <LinkV2 path={`/users/${user?.slug}`} size="md" mb="24px" ml="10px">
              mon profil
            </LinkV2>
            <LinkV2 path="/users/dashboard" size="md" mb="24px" ml="10px">
              mon espace
            </LinkV2>
          </>
        )}
      </Flex>
      {user ? (
        <Flex
          w="100%"
          justifyContent="center"
          _hover={{ fontWeight: 'bold', color: 'primary.DEFAULT' }}
          as="button"
          align="center"
          onClick={logout}
          color="white"
        >
          <IconLogout fill="white" mr="4px" />
          <Text size="xs" color="white">
            Me déconnecter
          </Text>
        </Flex>
      ) : (
        <Button
          as="a"
          variant="primaryW"
          size="md"
          mt="auto"
          mx="auto"
          href={toV2Link('/authenticate', platform, lang)}
        >
          se connecter
        </Button>
      )}
    </Flex>
  )
}

export default MobileNav
